

















import {Component, Vue} from "vue-property-decorator";
import '@/assets/css/user/main.css';

@Component
export default class AuthOk extends Vue{
   message : string | null;
   url : string;

  constructor() {
    super();
    this.message = null;
    this.url = '';
  }

  async created(){
    if(await this.$route.name==='authOK_Already') this.message = '이메일 인증이 이미 완료되었습니다.';
    else this.message = '✔이메일 인증이 완료되었습니다';
  }



}
